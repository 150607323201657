import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';


import { getDateLocale, phoneNumberValidation } from '@/utils/utils';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../../components/UI/Input/${layout}`));
const Label = lazy(() => import(`../../../components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const Message = lazy(() => import(`../../../components/UI/Message/${layout}`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const SubHeader = lazy(() => import(`../../../components/SubHeader/${layout}`));
const FormStyle = require(`../../../components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { t } = useTranslation();

  const showError = (field, validationErrors, isNotValid) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
  };

  return (
    <>
      <Styled.Wrapper>
        <div>
          <SubHeader
            text={t('banner.tellUs')}
            className="subHeaderNew"
            variant="h1"
          />
          <div className="formWrap">
            <FormStyle.default.FieldsWrapper width="100%" className="coloumn mb-5">
              <Label variant="body1">{t('banner.haveCameraQ')}</Label>
              <FormStyle.default.CheckBoxWrapper direction="row">
                <RadioButton
                  aria-labelledby="haveCamera"
                  value={values.haveCamera}
                  options={[
                    { label: t('banner.yes'), value: "yes" },
                    { label: t('banner.no'), value: "no" },
                  ]}
                  onClick={(event) => {
                    handleEdit(event, {
                      field: 'haveCamera',
                      type: 'radio'
                    });
                  }}
                />
              </FormStyle.default.CheckBoxWrapper>
            </FormStyle.default.FieldsWrapper>
            {<FormStyle.default.FieldsWrapper width="100%" className={`coloumn ${values?.haveCamera === "yes" ? "transition visible" : "transition"}`}>
              <Label variant="body1">{t('banner.cameraProviderQ')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="cameraProvider"
                  handleOnChange={(event) => {
                    handleEdit((event || ''), { field: 'cameraProvider' });
                  }}
                  as={Input}
                  value={values['cameraProvider']}
                  placeholder={t('banner.cameraProviderPlaceholder')}
                  type="text"
                />
                {showError(
                  'cameraProvider',
                  errors,
                  isNotValid,
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>}
            <FormStyle.default.FieldsWrapper width="100%" className="coloumn">
              <Label variant="body1">{t('banner.haveInsuranceQ')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="insuranceProvider"
                  handleOnChange={(event) => {
                    handleEdit(event, { field: 'insuranceProvider' });
                  }}
                  as={Input}
                  value={values['insuranceProvider']}
                  placeholder={t('banner.insuranceProviderPlaceholder')}
                  type="text"
                />
                {showError(
                  'insuranceProvider',
                  errors,
                  isNotValid,
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            {<FormStyle.default.FieldsWrapper width="100%" className="coloumn">
              <Label variant="body1">{t('banner.policyRenewQ')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="endDate"
                  onChange={(event) => {
                    handleEdit(event, { field: 'endDate', type: 'date' });
                  }}
                  as={ReactDatePicker}
                  locale={getDateLocale()}
                  placeholderText={t('common.datePicker')}
                  value={values.endDate}
                  showPopperArrow={false}
                  showTimeSelect={false}
                  showDisabledMonthNavigation
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date()}
                />
                 {showError(
                  'endDate',
                  errors,
                  isNotValid,
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>}
            <FormStyle.default.FieldsWrapper width="100%" className="coloumn">
              <Label variant="body1">{t('banner.email')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="email"
                  handleOnChange={(event) => {
                    handleEdit(event, { field: 'email' });
                  }}
                  as={Input}
                  value={values['email']}
                  placeholder={t('banner.emailPlaceholder')}
                  type="text"
                />
                {showError(
                  'email',
                  errors,
                  isNotValid,
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.FieldsWrapper width="100%" className="coloumn">
              <Label htmlFor="phone" variant="body1">
                {t('banner.phone')}
              </Label>
              <FormStyle.default.TextWrapper>
                  <Field
                    name="phone"
                    id="phone"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'phone' })
                    }
                    as={Input}
                    placeholder={t('banner.phoneNumberPlaceHolder')}
                    value={values['phone']}
                    type="text"
                    updateValue={phoneNumberValidation}
                  />
                  {showError(
                    'phone',
                    errors,
                    isNotValid,
                  )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          </div>
        </div>
      </Styled.Wrapper>
      {typeof customProps?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={t(customProps.errorMessage)}
        />
      )}
      <Styled.submitUser>
        <FormStyle.default.ButtonsWrapper className="submitSection">
          <Button
            className="buttonStyle"
            type="reset"
            label={t('banner.later')}
            onClick={(e) => {
              cancelAction();
            }}
          />
          <Button
            type="submit"
            className="buttonStyle"
            label={t('banner.submit')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </Styled.submitUser>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
