import React, { lazy, Suspense } from 'react';

import Questionnaire from '@/components/modules/QuestionnaireBanner';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const AppLayout = lazy(() => import(`../components/Layouts/Base`));
const Loader = require(`@/components/Loader`).default;

const RenderComponent = ({
  setAuth,
  config,
  folderPath,
  setFilterPopup,
  ...rest
}) => {
  const history = useHistory();
  const i18n = useTranslation();
  const theme = useTheme();
  // useEffect(()=>{
  //   if(window.window.$environment.SERVICE_PROVIDER === 'tp' && window?.zE){
  //   const token = getCookie(COOKIE_AUTH_DETAILS)
  //   if(token){
  //   window.zE(function() {
  //     window.zE.identify({
  //       name: `${token.firstName} ${token.lastName}`,
  //       email: token.email,
  //       organization: ''
  //     });
  //   })}
  // }
  // }, [])
  const AsyncComponent = lazy(() => {
    return import(`@/pages${folderPath}${config.component}`);
  });

  return (
    <Suspense fallback={<Loader />}>
      <Questionnaire />
      <AppLayout
        setAuth={setAuth}
        pageTitle={config.title}
        layout={config.layout}
        folderPath={folderPath}
        showFilterPopup={setFilterPopup}
        i18n={i18n}
      >
        <AsyncComponent
          {...rest}
          module={config.module}
          history={history}
          i18n={i18n}
          theme={theme}
        showFilterPopup={setFilterPopup}
        />
      </AppLayout>
    </Suspense>
  );
};

export default RenderComponent;
