export const phoneRegExp = /\d{3}-\d{3}-\d{4}/;
export const emailRegExp = /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,5})$/;
// export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,15}$/;

// This will work for '#' and other special characters
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^[\]{}|\\\/~+-_#])[A-Za-z\d@$!%*?&^[\]{}|\\\/~+-_#]{6,15}$/;

export const eventTypes = [
    {
      label: 'Parking Motion',
      value: 'PARKING_MOTION'
    },
    {
      label: 'Parking Hit',
      value: 'PARKING_HIT'
    },
    {
      label: 'Parking Heavy Hit',
      value: 'PARKING_HEAVY_HIT'
    },
    {
      label: 'Driving Hit',
      value: 'DRIVING_HIT'
    },
    {
      label: 'Driving Heavy Hit',
      value: 'DRIVING_HEAVY_HIT'
    },
    {
      label: 'Hard Acceleration',
      value: 'HARD_ACCEL'
    },
    {
      label: 'Hard Brake',
      value: 'HARD_BRAKE'
    },
    {
      label: 'Sharp Turn',
      value: 'SHARP_TURN'
    },
    {
      label: 'Harsh Acceleration',
      value: 'HARSH_ACCEL'
    },
    {
      label: 'Harsh Brake',
      value: 'HARSH_BRAKE'
    },
    {
      label: 'Harsh Turn',
      value: 'HARSH_TURN'
    },
    {
      label: 'Severe Acceleration',
      value: 'SEVERE_ACCEL'
    },
    {
      label: 'Severe Brake',
      value: 'SEVERE_BRAKE'
    },
    {
      label: 'Severe Turn',
      value: 'SEVERE_TURN'
    },
    {
      label: 'Potential Incident Low',
      value: 'POTENTIAL_INCIDENT_LOW'
    },
    {
      label: 'Potential Incident Medium',
      value: 'POTENTIAL_INCIDENT_MEDIUM'
    },
    {
      label: 'Potential Incident High',
      value: 'POTENTIAL_INCIDENT_HIGH'
    },
    {
      label: 'Asleep',
      value: 'ASLEEP'
    },
    {
      label: 'Drowsiness',
      value: 'DROWSINESS'
    },
    {
      label: 'Yawn',
      value: 'YAWN'
    },
    {
      label: 'Daydreaming',
      value: 'DAYDREAMING'
    },
    {
      label: 'Using Phone',
      value: 'USING_PHONE'
    },
    {
      label: 'Distracted',
      value: 'DISTRACTED'
    },
    {
      label: 'Smoking',
      value: 'SMOKING'
    },
    {
      label: 'No Seatbelt',
      value: 'NO_SEATBELT'
    },
    {
      label: 'Idle No Driver',
      value: 'IDLE_NO_DRIVER'
    },
    {
      label: 'Headway Monitoring Warning',
      value: 'HEADWAY_MONITORING_WARNING'
    },
    {
      label: 'Headway Monitoring Emergency',
      value: 'HEADWAY_MONITORING_EMERGENCY'
    },
    {
      label: 'Lane Departure Warning',
      value: 'LANE_DEPARTURE_WARNING'
    },
    {
      label: 'Stop Sign Violation',
      value: 'STOP_SIGN_VIOLATION'
    },
    {
      label: 'Power Lost',
      value: 'POWER_LOST'
    },
    {
      label: 'No Driver',
      value: 'NO_DRIVER'
    },
    {
      label: 'Manual',
      value: 'MANUAL'
    },
    {
      label: 'Forward Collision Warning',
      value: 'FORWARD_COLLISION_WARNING'
    }
];

export const BANNER_VALIDATOR_TIME_IN_S = 86400;