import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { array, boolean, object, string } from 'yup';

import { COOKIE_AUTH_DETAILS, imageUrls, pageUrls } from '@/config/constants/keys';
import { UI_DATE_FORMAT } from '@/config/constants/static';
import { getCookie } from '@/services/cookie';
import { removeLocalStorage } from '@/services/storage';
import { emailRegExp, phoneRegExp } from '@/utils/constants';
import {
  getImagePath,
  getListOfSelectedField,
  hasEnabledFeature,
  hasValue,
  isInsuracePortalUser
} from '@/utils/utils';
import moment from 'moment';
import RenderComponent from './RenderComponent';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const UserForm = ({
  apiStatus,
  saveUserData,
  user,
  history,
  saveDocument,
  deleteDocument,
  callback,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  regions,
  notificationList,
  i18n,
  type,
  handleDownloadDoc,
  fetchCountData,
  downloadDocument,
  logBook,
  fleetCompanies,
  vehicleList,
  terminals,
  departments,
  subDepartments,
  disabledCriteria,
  handleLogout,
  Styled,
  getDrivers,
  downloadUrl,
  viewOnly,
  userRole,
  newUI,
  onFleetChange
}) => {
  const [token, setToken] = useState(null);
  const apiId = type + 'SaveUserData';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const [item, setItem] = useState({});
  const insuracePortalUserRole = isInsuracePortalUser();
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let url = downloadUrl ? downloadUrl : '';
    document.getElementById('policyDownload').src = url;
  }, [downloadUrl]);
  const [validationlist, setValidation] = useState(window?.$environment?.SERVICE_PROVIDER === 'webfleet' ? {
    notificationId: array().when('hideNotification', {
      is: (val) => {
        return val;
      },
      then: array().required(i18n.t('common.fieldRequiredMessage')),
      otherwise: array(),
    }),
    annotationEld: string().when('exemptFromEld', {
      is: (val) => val,
      then: string().required(i18n.t('common.fieldRequiredMessage')),
      otherwise: string(),
    })
  } : {
    firstName: string()
      .test(
        'len',
        i18n.t('common.atleast2Chars'),
        (val) => val && val.length > 1
      )
      .required(i18n.t('common.fieldRequiredMessage')),
    lastName: string()
      .test(
        'len',
        i18n.t('common.atleast2Chars'),
        (val) => val && val.length > 1
      )
      .required(i18n.t('common.fieldRequiredMessage')),

    phone: string()
      .matches(phoneRegExp, i18n.t('common.validNumFormat'))
      .required(i18n.t('common.fieldRequiredMessage')),
    company: user?.userEditDto?.allRolesMap?.DRIVER && insuracePortalUserRole ? string().required('common.fieldRequiredMessage') : string(),
    email: user?.userEditDto?.allRolesMap?.DRIVER && window?.$environment?.SERVICE_PROVIDER === 'edvir' ? string().matches(emailRegExp, i18n.t('common.emailValidationMessage')) : string()
      .matches(emailRegExp, i18n.t('common.emailValidationMessage'))
      .required(i18n.t('common.fieldRequiredMessage')),
    hireDate: string().when("email", {
      is: () => window?.$environment?.SERVICE_PROVIDER !== 'webfleet',
      then: string().required('common.fieldRequiredMessage'),
      otherwise: string()
    }),
    roles: boolean().nullable().when([
      'fleetManagerRole',
      'adminRole',
      'driverRole',
      'mechanicRole',
      'serviceManagerRole',
      'underWriterRole',
      'lossControlRole',
      'claimAdjusterRole',
    ], (fM, aR, dR, mR, sM, uW, lC, cA, schema) => {
      return schema.test({
        test: () => {
          return !(!fM && !aR && !dR && !mR && !sM && !uW && !lC && !cA);
        },
        message: i18n.t('common.fieldRequiredMessage')
      });
    }),
    licenseNumber: string().when('driverRole', {
      is: (val) => {
        return val;
      },
      then: string().required(i18n.t('common.fieldRequiredMessage')).matches(/^[a-zA-Z0-9]*$/, i18n.t('common.validLicenseNumber')),
      otherwise: string().nullable()
    }),
    licenseState: string().when('driverRole', {
      is: (val) => {
        return val;
      },
      then: string().required(i18n.t('common.fieldRequiredMessage')),
      otherwise: string().nullable()
    }),
    empId: string().when('driverRole', {
      is: (val) => {
        return val;
      },
      then: string().trim().strict(true).max(45, 'Id must be at most 45 characters').required(i18n.t('common.fieldRequiredMessage')),
      otherwise: string().max(45, 'Id must be at most 45 characters')
    }),
    notificationId: array().when('hideNotification', {
      is: (val) => {
        return val;
      },
      then: array().required(i18n.t('common.fieldRequiredMessage')),
      otherwise: array(),
    }),
    annotationEld: string().when('exemptFromEld', {
      is: (val) => val,
      then: string().required(i18n.t('common.fieldRequiredMessage')),
      otherwise: string(),
    })
  });
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setToken(getCookie(COOKIE_AUTH_DETAILS));
  }, []);
  initialValues.documentsList = user?.documentsList;
  useEffect(() => {
    // setItem(user?.userEditDto?.user || {});
    setInitialValues({
      id: user?.userEditDto?.user.id,
      firstName: user?.userEditDto?.user.firstName || '',
      lastName: user?.userEditDto?.user.lastName || '',
      phone: user?.userEditDto?.user.phone || '',
      email: user?.userEditDto?.user.email || '',
      hireDate: user?.userEditDto?.user.hireDate || '',
      otherContact: user?.userEditDto?.user?.otherContact || '',
      driverRole: user?.userEditDto?.allRolesMap?.DRIVER || false,
      initialUserDriver: user?.userEditDto?.allRolesMap?.DRIVER || false,
      adminRole: user?.userEditDto?.allRolesMap?.ADMIN || false,
      underWriterRole: user?.userEditDto?.allRolesMap?.UNDER_WRITER || false,
      lossControlRole: user?.userEditDto?.allRolesMap?.LOSS_CONTROL || false,
      claimAdjusterRole: user?.userEditDto?.allRolesMap?.CLAIM_ADJUSTER || false,
      fleetManagerRole: user?.userEditDto?.allRolesMap?.FLMANAGER || false,
      mechanicRole: user?.userEditDto?.allRolesMap?.MECHANIC || userRole?.mechanic,
      enabledNonDotTripForCompany: user?.enabledNonDotTripForCompany || false,
      fleetManagerRoleDefault: user?.userEditDto?.allRolesMap?.OWNER || false,
      serviceManagerRole: user?.userEditDto?.allRolesMap?.SERVICE_MANAGER || false,
      roles:
        user?.userEditDto?.allRolesMap?.DRIVER ||
          user?.userEditDto?.allRolesMap?.ADMIN ||
          user?.userEditDto?.allRolesMap?.FLMANAGER ||
          user?.userEditDto?.allRolesMap?.MECHANIC ||
          user?.userEditDto?.allRolesMap?.OWNER ||
          user?.userEditDto?.allRolesMap?.SERVICE_MANAGER ||
          user?.userEditDto?.allRolesMap?.UNDER_WRITER ||
          user?.userEditDto?.allRolesMap?.LOSS_CONTROL ||
          user?.userEditDto?.allRolesMap?.CLAIM_ADJUSTER ||
          userRole?.mechanic
          ? true
          : false,
      licenseNumber: user?.userEditDto?.user.licenseNumber || '',
      licenseState: user?.userEditDto?.user.licenseState
        ? {
          label: user?.userEditDto?.user.licenseState,
          value: user?.userEditDto?.user.licenseState
        }
        : '',
      company: user?.userEditDto?.user.company
        ? {
          label: user?.userEditDto?.user.company?.name,
          value: user?.userEditDto?.user.company?.id
        }
        : '',
      empId: user?.userEditDto?.user.empId || '',
      empPassword: user?.userEditDto?.user.empPassword || '',
      annotationEld: user?.userEditDto?.user.annotationEld || '',
      regionId:
        (regions &&
          regions.length &&
          regions.filter(
            (reg) => reg.value === user?.userEditDto?.regionId
          )[0]) ||
        '',
      notificationId: notificationList?.length ? notificationList.filter((item) =>
        user?.userEditDto?.settings?.HIDE_NOTIFICATION_VALUES?.includes(item.value)
      ) : [],
      hideNotification: user?.userEditDto?.settings?.HIDE_NOTIFICATION || false,
      terminalId:
        (terminals &&
          terminals.length &&
          terminals.filter(
            (ter) => ter.value === user?.userEditDto?.terminalId
          )[0]) ||
        '',
      departmentId:
        (departments &&
          departments.length &&
          departments.filter(
            (dep) => dep.value === user?.userEditDto?.departmentId
          )[0]) ||
        '',
      subDepartmentId:
        (subDepartments &&
          subDepartments.length &&
          subDepartments.filter(
            (sub) => sub.value === user?.userEditDto?.subDepartmentId
          )[0]) ||
        '',
      idsPubVehiclesOfMechanic: vehicleMechanicList.filter((item) =>
        user?.userEditDto?.idsPubVehiclesOfMechanic?.includes(item.value)
      ),
      idPubVehicleOfDriver:
        vehicleDriverList.filter((item) => {
          return item.value === user?.userEditDto?.idPubVehicleOfDriver;
        })[0] || '',
      enableYardMoves: user?.userEditDto?.user.enableYardMoves,
      nonDotTrip:
        window.$environment.SERVICE_PROVIDER === 'clutch' ||
          !hasEnabledFeature('dotTripEnabled')
          ? null
          : user?.userEditDto?.user.nonDotTrip,
      enablePersonalConveyance:
        user?.userEditDto?.user.enablePersonalConveyance,
      agExemption: user?.userEditDto?.user.agExemption,
      bigDayShortHaul: user?.userEditDto?.user.bigDayShortHaul,
      exemptFromEld: user?.userEditDto?.user.exemptFromEld,
      isDvirOnly: user?.userEditDto?.user.isDvirOnly || false,
      assignedVehicleType:
        user?.assignedVehicleType !== null &&
          user?.assignedVehicleType !== undefined
          ? {
            label: user?.assignedVehicleType,
            value: user?.assignedVehicleType
          }
          : { label: 'Property', value: 'Property' },
      assignedCycleRules:
        user?.assignedCycleRules !== null &&
          user?.assignedCycleRules !== undefined
          ? user?.assignedCycleRules &&
          objectArrayFromSameField(user?.assignedCycleRules)
          : '',
      assignedCycleExceptions:
        user?.assignedCycleExceptions !== null &&
          user?.assignedCycleExceptions !== undefined
          ? user?.assignedCycleExceptions &&
          objectArrayFromSameField(user?.assignedCycleExceptions)
          : '',
      defaultCycleRule: user?.defaultCycleRule
        ? { label: user?.defaultCycleRule, value: user?.defaultCycleRule }
        : ''
    });
  }, [user?.userEditDto]);
  // useEffect(()=>{
  //   setInitialValues({
  //     ...initialValues,
  //     documentsList: user.documentsList
  //   })
  // },[user.documentsList])
  const driverVehicleCallback = (item, field, value) => {
    return {
      label: item[value],
      value: item[field]
    };
  };
  const objectArrayFromDiffFields = (list, field, value, callback) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field, value);
        else
          return {
            label: item[value],
            value: item[field]
          };
      })
      : [];
  };
  const fleetVehicleList = objectArrayFromDiffFields(
    vehicleList,
    'vehicleId',
    'name',
    driverVehicleCallback
  )
  const vehicleDriverList = objectArrayFromDiffFields(
    user?.userEditDto?.vehicleList,
    'vehicleId',
    'name',
    driverVehicleCallback
  );
  const vehicleMechanicCallback = (item, field, value) => {
    if (!user?.idsPubVehiclesOfMechanic?.contains(item.vehicleId)) {
      if (item?.mechanicId) {
        return {
          label: user?.userEditDto?.idsPubVehiclesOfMechanic?.includes(
            item[field]
          )
            ? `${item[value]}`
            : `${item[value]}-Assigned`,
          value: item[field],
          isDisabled: true
        };
      } else
        return {
          label: item[value],
          value: item[field]
        };
    }
  };
  const vehicleMechanicList = objectArrayFromDiffFields(
    user?.userEditDto?.vehicleList,
    'vehicleId',
    'name',
    vehicleMechanicCallback
  );
  const objectArrayFromSameField = (list) => {
    return list?.length
      ? list.map((item) => {
        return {
          label: item,
          value: item
        };
      })
      : [];
  };

  const getEmail = (fields) => {
    if (window?.$environment?.SERVICE_PROVIDER === 'edvir' && fields.driverRole && !fields.email) {
      if (type == 'add') return fields.phone.replace(/[-]/g, "") + '@dvironly.com'
      else return `${fields.phone.replace(/[-]/g, "")}+${fields.id}@dvironly.com`
    } else {
      return fields.email
    }
  }

  const submitUserData = (fields) => {
    let finalData = {
      user: {
        firstName: fields.firstName,
        ...(insuracePortalUserRole && fields.driverRole ? { company: fields.company.value ? { id: fields.company.value } : null } : {}),
        lastName: fields.lastName,
        phone: fields.phone,
        email: getEmail(fields),
        id: fields.id,
        hireDate: fields.hireDate ? moment(fields.hireDate).format(UI_DATE_FORMAT) : '',
      },
      regionId: (fields.regionId && fields.regionId.value) || null,
      terminalId: (fields.terminalId && fields.terminalId.value) || null,
      departmentId:
        (fields.departmentId && fields.departmentId.value) || null,
      subDepartmentId:
        (fields.subDepartmentId && fields.subDepartmentId.value) || null
    };
    if (type === 'edit') {
      finalData.user.otherContact = fields.otherContact || null;
      finalData.settings = {
        HIDE_NOTIFICATION: fields.hideNotification || false,
        HIDE_NOTIFICATION_VALUES: fields.notificationId?.map(ele => ele.value) || []
      };

    }
    if (fields.driverRole || fields.mechanicRole || fields?.serviceManagerRole) {
      finalData.user.empId = fields.empId && fields.empId.trim().length > 0 ? fields.empId : null;
      finalData.user.empPassword = fields.empPassword && fields.empPassword.trim().length > 0 ? fields.empPassword : null;
    }
    if (fields.driverRole) {
      finalData.user.licenseNumber = fields.licenseNumber || null;
      finalData.user.licenseState = fields.licenseState?.value || null;
      finalData.user.enableYardMoves = fields.enableYardMoves || false;
      finalData.user.enablePersonalConveyance =
        fields.enablePersonalConveyance || false;
      finalData.user.agExemption = fields.agExemption || false;
      finalData.user.bigDayShortHaul = fields.bigDayShortHaul || false;
      finalData.user.annotationEld = fields.annotationEld || null;
      finalData.idPubVehicleOfDriver =
        parseInt(fields.idPubVehicleOfDriver?.value) || null;
      finalData.assignedVehicleType =
        fields.assignedVehicleType.value || null;
      finalData.defaultCycleRule = fields.defaultCycleRule.value || null;
      finalData.assignedCycleExceptions = fields.assignedCycleExceptions
        ? fields.assignedCycleExceptions.map((key) => {
          return key.value;
        })
        : null;
      finalData.assignedCycleRules = fields.assignedCycleRules
        ? fields.assignedCycleRules.map((key) => {
          return key.value;
        })
        : null;
      finalData.user.nonDotTrip = fields.nonDotTrip;
      finalData.user.exemptFromEld = fields.exemptFromEld;
      finalData.user.isDvirOnly = fields.isDvirOnly || false;
    }
    if (fields.mechanicRole) {
      finalData.idsPubVehiclesOfMechanic =
        getListOfSelectedField(
          fields.idsPubVehiclesOfMechanic,
          'value'
        ) || null;
    }
    let roles = [];
    if (fields.mechanicRole) roles.push('MECHANIC');
    if (fields.driverRole) roles.push('DRIVER');
    if (fields.adminRole) roles.push('ADMIN');
    if (fields.fleetManagerRole) roles.push('FLMANAGER');
    if (fields.fleetManagerRoleDefault) roles.push('OWNER');
    if (fields.serviceManagerRole) roles.push('SERVICE_MANAGER');
    if (fields.underWriterRole) roles.push('UNDER_WRITER');
    if (fields.lossControlRole) roles.push('LOSS_CONTROL');
    if (fields.claimAdjusterRole) roles.push('CLAIM_ADJUSTER');
    finalData.userRoles = [...roles];

    saveUserData({
      data: {
        data: {
          ...finalData
        }
      },
      apiId,
      callback: (data) => {
        if (
          data.user.email !== token.email &&
          data.userRoles.includes('OWNER') &&
          token.roles.includes('OWNER')
        ) {
          handleLogout({
            apiId: 'logout',
            callback: (type, message) => {
              if (type === 'error') {
                callback(type, message);
              } else {
                removeLocalStorage('currentLayout');
                //window.location.reload();
                window.location.href = pageUrls.login;
              }
            }
          });
        } else {
          let apiId = 'fetchCountApi';
          if (window.$environment.CURRENT_LAYOUT === 'SpireonNew')
            history.goBack();
          else if (window.$environment.SERVICE_PROVIDER === 'spireon')
            history.push(pageUrls.driverStatusViewMore);
          else
            history.push(pageUrls.userList);
          fetchCountData({
            apiId
          });
          getDrivers({
            apiId: 'getDrivers'
          });
        }
      }
    });
  }

  const states = objectArrayFromSameField(user.states);
  const companies = objectArrayFromDiffFields(fleetCompanies, 'id', 'name')
  const allCycleRules = objectArrayFromSameField(user?.allCycleRules);
  const allCycleExceptions = objectArrayFromSameField(user?.allCycleExceptions);
  const allVehicleTypes = objectArrayFromSameField(user?.allVehicleTypes);

  if (type === 'edit') {
    initialValues.timzoneForDailylog = user?.userEditDto?.timzoneForDailylog;
    initialValues.deviceType =
      !user.useAOBRD && !!user.requiredELD
        ? 'NONE'
        : !user.requiredELD
          ? 'ELD'
          : !user.useAOBRD
            ? 'AOBRD'
            : '';
  }
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {

          if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && type === 'add' && fields.driverRole) {
            setModalListObject({
              content: () => (
                <div className="deleteParent">
                  <div className="imageSection">
                    <img
                      src={getImagePath(imageUrls.confirmIcon)}
                      alt="Are you sure"
                    />
                  </div>
                  <h2>
                    Please be advised that insurance coverage cannot be bound, added, cancelled, or otherwise changed through the Constitution Risk Management telematics dashboard.

                    All coverage changes will need to be submitted to your insurance agent and confirmed in writing by your insurance carrier.
                  </h2>
                  <div className="deletePopupBtn">
                    <div className="cancelBtn">
                      <Button
                        label={i18n.t('common.ok')}
                        type={'reset'}
                        onClick={() => {
                          setOpen(false);
                        }}
                      ></Button>
                    </div>
                    <div className="deletebtn">
                      <Button
                        label={i18n.t('common.proceedToAdd')}
                        onClick={() => {
                          setOpen(false);
                          submitUserData(fields)
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )
            });
            setOpen(true);
          } else {
            submitUserData(fields)
          }
        }
        }
        customProps={{
          hasApiStatus,
          saveDocument,
          deleteDocument,
          apiStatus,
          callback,
          fleetVehicleList,
          fetchTerminals,
          fetchDepartments,
          fetchSubDepartments,
          regions,
          validationlist,
          states,
          companies,
          vehicleDriverList,
          vehicleMechanicList,
          user,
          type,
          policies: user?.policies || [],
          handleDownloadDoc,
          showPolicies: user?.showPolicies || false,
          downloadDocument,
          logBook,
          allCycleRules,
          allCycleExceptions,
          allVehicleTypes,
          terminals,
          notificationList,
          departments,
          subDepartments,
          disabledCriteria,
          Styled,
          viewOnly,
          isAddMechanic: userRole?.mechanic,
          newUI,
          insuracePortalUserRole,
          onFleetChange
        }}
        handleOnCancel={() => {
          if (window.$environment.CURRENT_LAYOUT === 'SpireonNew')
            history.goBack();
          else if (window.$environment.SERVICE_PROVIDER === 'spireon')
            history.push(pageUrls.driverStatusViewMore);
          else
            history.push(pageUrls.userList);
        }}
        validationSchema={object().shape({
          ...validationlist
        })}
      />
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
      <iframe id="policyDownload" style={{ display: 'none' }}></iframe>
    </>
  );
};

UserForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
  saveDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  fetchTerminals: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  fetchSubDepartments: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleDownloadDoc: PropTypes.func.isRequired
};

export default UserForm;
