import React from 'react';
import Form from './Form';
import Styled from './style';

const Component = (props) => {
  return (
    <Styled.MainWrapper>
      <Form {...props} Styled={Styled} />
    </Styled.MainWrapper>
  );
};

export default Component;

Component.propTypes = {};
