import React, { lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { imageUrls } from '@/config/constants/keys';
import { BACKEND_DATE_FORMAT } from '@/config/constants/static';
import { phoneRegExp } from '@/utils/constants';
import { getAuthDetails, getImagePath, hasValue } from '@/utils/utils';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const TIMEOUT_TIME = 5;

const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const SubHeader = lazy(() => import(`../../../components/SubHeader/${layout}`));
const Button = lazy(() => import(`../../../components/UI/Button/${layout}`));
const SuccessIcon = lazy(() => import(`@/components/SuccessIcon/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));


const validationSchema = {
  cameraProvider: Yup.string().when("haveCamera", {
    is: (val) => val === "yes",
    then: Yup.string().trim().strict(true).required('common.fieldRequiredMessage'),
    otherwise: Yup.string()
  }),
  insuranceProvider: Yup.string()
  .trim()
  .required('common.fieldRequiredMessage')
  .min(1, 'common.fieldRequiredMessage'),
  endDate: Yup.date().required('common.fieldRequiredMessage'),
  email: Yup.string().email('banner.emailValidation').required('common.fieldRequiredMessage'),
  phone: Yup.string().matches(phoneRegExp, ('common.validNumFormat')).required('common.fieldRequiredMessage'),
};

const QuestionnaireBannerForm = ({
  action: onCancelModal,
}) => {
  const { i18n } = useTranslation();
  const auth = getAuthDetails();
  const { gigBanner: gigBannerDispatch } = useDispatch();

  const [showThanks, setShowThanks] = useState(false);
  const apiStatus = useSelector(state => state.asyncRequests);

  const hasApiStatus = hasValue(apiStatus.status, 'api', 'saveGigBanner');

  const [errorMessage, setErrorMessage] = useState('');


  const onSubmit = async (fields) => {
    const responseObj = {};
    responseObj.hasCameras = fields?.haveCamera === "yes" ? true : false;
    responseObj.cameraProvider = fields?.haveCamera ? fields?.cameraProvider : null;
    responseObj.insuranceProvider = fields?.insuranceProvider || null;
    responseObj.policyRenewDate =  moment(fields?.endDate).format(BACKEND_DATE_FORMAT) || null;
    responseObj.email = fields?.email || null;
    responseObj.phone = fields?.phone || null;
    await gigBannerDispatch.saveGigBanner({
      data: responseObj,
      apiId: 'saveGigBanner',
      callback: (isSuccess, response, error) => {
        if (isSuccess) {
          localStorage.setItem('bannerDate', 'submitted');
          setShowThanks(true);
        } else if (error) {
          setErrorMessage(error);
        }
      }
    })
  }

  const thanksComponent = (
    <>
      <Grid container justify="center">
        <SuccessIcon />
        <SubHeader
          text={i18n.t('banner.thankYou')}
          className="subHeaderNew"
          variant="h1"
        />
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('banner.close')}
          onClick={() => onCancelModal(true)}
        />
      </Grid>
    </>
 
  );

  const customProps = {
    errorMessage: hasApiStatus ? hasApiStatus?.errorMessage : errorMessage,
  };

  return showThanks ? thanksComponent : (
    <>
    <div id="closeBtn">
      <Icon
        onClick={() => onCancelModal()}
        src={getImagePath(imageUrls.closeIcon)}
      ></Icon>
    </div>  
      <Form
        type="server"
        direction="column"
        initialValues={{
          haveCamera: 'no',
          email: auth?.sub || '',
          cameraProvider: '',
          phone: '',
          insuranceProvider: '',
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={onSubmit}
        customProps={customProps}
        handleOnCancel={() => onCancelModal()}
        validationSchema={Yup.object().shape({
          ...validationSchema
        })}
      />
    </>
  );
};

QuestionnaireBannerForm.propTypes = {};

export default QuestionnaireBannerForm;
