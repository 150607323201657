export default {
  'Select All': 'Seleccionar todo',
  'This field is required': 'Este campo es obligatorio',
  'Add New Company': 'Añadir nueva empresa',
  'COMPANY REGISTRATION': 'REGISTRO DE LA EMPRESA',
  'COMPANY INFORMATION': 'INFORMACIÓN DE LA EMPRESA',
  'SUBSCRIPTION LIST': 'LISTA DE SUSCRIPCIONES',
  Account: 'Cuenta',
  'Switch to Old View': 'Cambiar a vista anterior',
  Messaging: 'Mensajería',
  Logout: 'Cerrar sesión',
  'Total Hours Worked': 'Total de horas trabajadas',
  'Total Distance': 'Distancia total',
  'USER INFORMATION': 'Información del usuario',
  'Driver Name': 'Nombre del conductor',
  'Vehicle Name': 'Nombre del vehiculo',
  Upload: 'Subir a',
  'No file selected': 'Ningún archivo seleccionado',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Reset: 'Reiniciar',
  viewSample: 'Ver muestra',
  'The email is not valid': 'El email no es valido',
  'LOG BOOK': 'Libro de registro',
  Description: 'Descripción',
  'Date (in UTC)': 'Date (in UTC)',
  'VIEW DOCUMENT': 'VER DOCUMENTO',
  NEXT: 'SIGUIENTE',
  PREV: 'Previa',
  Actions: 'Acciones',
  Annotation: 'Anotación',
  'Modified By': 'Modificado por',
  Version: 'Versión',
  ELD: 'ELD',
  OTHER: 'OTRO',
  'Un authorized': 'Sin autorización',
  'LOG IN': 'INICIAR SESIÓN',
  REGISTRATION: 'REGISTRO',
  'Forgot password?': 'Se te olvidó tu contraseña?',
  'Remember me': 'Recuérdame',
  'Log in': 'Iniciar sesión',
  'If your email address is registered with us, you will receive a password recovery link at your email address.': 'If your email address is registered with us, you will receive a password recovery link at your email address.',

  'FDCSA info':
    'NB: Esto enviará archivos a FMCSA por Correo Electrónico y / o servicios web como se describe en el Apéndice A a la Subparte B de la Parte 395',
  'mail@gmail.com': 'mail@gmail.com',
  email: 'Correo Electronico',
  'EDIT DAILY LOG': 'EDIT DAILY LOG',
  Email: 'Correo Electronico',
  'Send a Message': 'Enviar un mensaje',
  'Select Drivers here': 'Seleccione controladores aquí',
  Type: 'Tipo',
  'Select Drivers here...': 'Seleccione controladores aquí',
  'Email or Employee Id': 'Correo Electrónico o ID del empleado',
  Logs: 'Registros',
  'Start Time': 'Hora de inicio',
  'End Time': 'Fin del tiempo',
  'Assigned Driver': 'Controlador asignado',
  'Co-Driver': 'Copiloto',
  Status: 'Estado',
  PREVIOUS: 'ANTERIOR',
  'First Name': 'Nombre de pila',
  'Last Name': 'Apellido',
  apology: 'Nuestras disculpas',
  'page moved': 'Esa página fue movida o eliminada.',
  'send feedback': 'Envíenos sus comentarios',
  here: 'aquí',
  footer: {
    address: {
      one: 'Oficina corporativa',
      two: 'Oficina',
      three: 'Online'
    },
    patent: 'Patente pendiente',
    copyright: 'Derechos de autor 2020',
    PrivacyLegal: 'Privacidad y legal',
  },
  resetPassword: 'Restablecer la contraseña',
  manageProfile: { editProfile: { heading: 'EDITAR PERFIL' } },
  common: {
    regeneratePdf: "Regenerar PDF",
    en: 'English',
    es: 'Spanish',
    hideNotification: 'Ocultar notificación',
    notificationList: 'Lista de notificación',
    company: 'Compañía',
    dotNumber: 'Número de DOT',
    address: 'Dirección',
    city: 'Ciudad',
    zipCode: 'Código Postal',
    date: 'Fecha',
    viewMore: 'Ver más',
    viewLess: 'Ver menos',
    manageDailyDocuments: 'GESTIONAR DOCUMENTOS DIARIOS',
    alerts: 'Alertas',
    submit: 'Enviar',
    type: 'Tipo',
    account: 'Cuenta',
    unhandled: 'No manejado',
    configuration: 'Configuration',
    emailAlerts: 'Email Alerts',
    alertToEmailInfo: "If no events are selected, it will be considered that all events should be sent.",
    driver: 'Conductor',
    dateFrom: 'Fecha desde',
    lastUpdated: 'Última actualización',
    viewAll: 'Ver todo',
    followVehicle: 'Seguir vehículo',
    dateTo: 'Fecha a',
    hoursWorked: 'Horas Trabajadas',
    vehicle: 'Vehículo',
    distance: 'Distancia',
    date: 'Fecha',
    cycleRule: 'Regla de ciclo',
    tools: 'Herramientas',
    tableEmptyMessage: 'No hay datos disponibles en la tabla',
    edit: 'Editar',
    'resetPassword': 'Restablecer la contraseña',
    history: 'Historia',
    view: 'Ver',
    totalHoursWorked: 'Total de horas trabajadas',
    totalDistance: 'Distancia total',
    save: 'Guardar',
    reset: 'Reiniciar',
    location: 'Ubicación',
    driverId: 'driverId',
    coDriverId: 'coDriverId',
    note: 'Nota',
    show: 'Espectáculo',
    coDriver: 'Copiloto',
    startTime: 'Hora de inicio',
    endTime: 'Fin del tiempo',
    assignedDriver: 'Conductor asignado',
    annotation: 'Anotación',
    status: 'Estado',
    fieldRequiredMessage: 'Este campo es obligatorio',
    passwordIsNotMatching:
      'La contraseña de confirmación no coincide con la contraseña',
    emailValidationMessage:
      'Por favor ingrese un formato de Correo Electrónico válido',
    description: 'Descripción',
    bulkDownloadReport: 'Informes de descarga masiva',
    previous: '<< Anterior',
    next: 'Siguiente >>',
    version: 'Versión',
    modifiedBy: 'Modificado por',
    actions: 'Acciones',
    cancel: 'Cancelar',
    driverName: 'Nombre del conductor',
    vehicleName: 'Nombre del Vehículo',
    upload: 'Subir a',
    clear: 'Borrar',
    clearAll: 'Borrar todo',
    userInformation: 'Información del usuario',
    document: 'Documento',
    documents: 'Documentos',
    userCredentials: 'CREDENCIALES DE USUARIO',
    firstName: 'Nombre',
    lastName: 'Apellido',
    cellPhone: 'Teléfono celular',
    email: 'Correo Electrónico',
    hireDate: 'Fecha de contratación',
    role: 'Función',
    fleetManager: 'Gestor de flota',
    mechanic: 'Mecánico',
    admin: 'Administrador',
    employeeId: 'ID de empleado',
    terminal: 'Terminal',
    password: 'Contraseña',
    departments: 'Departamentos',
    region: 'Región',
    department: 'Departamento',
    vehicleId: 'Identificación del vehículo',
    licenceNumber: 'Número de licencia',
    state: 'Estado',
    pageNotFound: 'Página no encontrada',
    unAuthorized: 'Sin autorización',
    export: 'Exportar',
    'Confirm Password': 'Confirmar Contraseña',
    'Cell Phone': 'Teléfono móvil',
    userMainInformation: 'INFORMACIÓN PRINCIPAL DEL USUARIO',
    logsHistory: 'Historial de Registro Diario',
    ok: 'OK',
    driverPhone: 'Teléfono del conductor',
    delete: 'Eliminar',
    exportAll: 'Exportar todo',
    confirmPasswordMessage: 'Por favor, introduzca el mismo valor de nuevo.',
    dotOfficerCode: 'DOT Código de oficial',
    pleaseSelect: 'Por favor, seleccione',
    resetPassword: 'Restablecer la contraseña',
    importUser: 'Importar usuario',
    import: 'Importar',
    template: 'Modelo',
    add: 'Añadir',
    sureConfirm: '¿Está seguro?',
    recoverConfirm: '¡No podrás recuperar este Vehiculos!',
    timezone: 'Zona horaria',
    deviceType: 'Tipo de dispositivo',
    policiesAndProcedures: 'Políticas y procedimientos',
    otherContact: 'Otro contacto',
    confirmPassword: 'Confirmar Contraseña',
    noBreadcrumbData: 'No se encontraron datos para trazar el BreadCrumb',
    make: 'Marca',
    plateNumber: 'Número de placa',
    gvw: 'GVW or Peso total de vehículo',
    nextMaintenanceDate: 'Siguiente fecha de mantenimiento',
    nextMaintenanceMileage: 'Siguiente mantenimiento Kilometraje',
    deviceSn: 'Dispositivo SN',
    trailerInformation: 'Información del remolque',
    vehicleInformation: 'Información del vehículo',
    customerName: 'Nombre del cliente',
    driverNamejobName: 'Nombre del trabajo',
    isValidVin:
      'El número VIN debe tener 17 caracteres de longitud. Números y letras solamente. I, O o Q no están permitidos',
    phone: 'Número de teléfono',
    photos: 'Fotos',
    carrierName: 'Portador',
    vehicles: 'Vehículos',
    trailerName: 'Remolques',
    fromLocation: 'Desde',
    toLocation: 'Hasta',
    mainAddress: 'Dirección principal',
    terminalAddress: 'Dirección de terminal',
    shipDocs: 'Documentos De Embarcación',
    timeZoneOffsetFromUTC: 'Desplazamiento de zona horaria desde UTC',
    locationOfLogSigning: 'Ubicación de la firma del registro',
    notes: 'Notas',
    breadcrumb: 'Filtros Aplicados:',
    logbookDetails: "Registro diario de {{name}} para el",
    atleast2Chars: 'Por favor ingrese al menos 2 caracteres.',
    refresh: 'Actualizar',
    mph: 'MPH',
    updatedTime: 'Hora actualizada',
    geocodedLocation: 'Ubicación geocodificada',
    truckId: 'ID del camión',
    assignedVehicle: 'Vehículo asignado',
    currentOdometer: 'Cuentakilómetros actual',
    trailer: 'Remolque',
    hardwareDevice: 'Dispositivo de hardware',
    popupMessage: '¡No podrás recuperar a esta usuaria!',
    activateMessage: '¡Activarás a esta usuaria!',
    minimumRow: 'Se requiere un mínimo de 1 fila',
    deletePopup: '¡Sí, elimínalo!',
    acceptPopup: '¡Sí, acéptalo!',
    noData: 'Nada que mostrar',
    validNumFormat: 'Complete el formato correcto (844-636-1360).',
    finish: 'Terminar',
    update: 'Actualizar',
    SHOW: 'Espectáculo',
    saveChanges: 'Guardar cambios',
    mainInformation: 'INFORMACION PRINCIPAL',
    dataImportSuccess: 'Los datos se han importado correctamente',
    user: 'Usuario',
    validName10000: 'maximum 10000 allowed',
    drivingState: 'Estado de conduccion',
    total: 'Total',
    fleetManagerDefault: 'Gestor de flota (defecto)',
    passwordValidMessage:
      'Password must have at least an uppercase character, a lowercase character, a digit, a special character, at least 6 characters and at most 15 characters long.',
    filterRequiredMessage: 'Please select atleast one filter',
    upgrade: 'Mejorar',
    reported: 'Reportado',
    exportData: 'Exportar datos',
    paymentName: 'Nombre en la tarjeta',
    cardDetails: 'Detalles de la tarjeta actualizados con éxito',
    usdotDetails: 'USDOT No ha sido eliminado',
    deleteRegion: 'Región eliminada correctamente',
    updateRegion: 'Región actualizada correctamente',
    terminalAdd: 'Terminal agregado con éxito',
    terminalUpdate: 'Terminal actualizado con éxito',
    deleteTerminal: 'Terminal eliminado exitosamente',
    departmentAdded: 'Departamento agregado correctamente',
    departmentUpdated: 'Departamento actualizado correctamente',
    deleteDepartment: 'Departamento eliminado correctamente',
    locationAdded: 'Ubicación agregada correctamente',
    locationUpdated: 'Ubicación actualizada correctamente',
    locationDeleted: 'Ubicación eliminada correctamente',
    partAdded: 'Piezas agregadas correctamente',
    timzone: 'Zona horaria',
    timeZone:
      'Su zona horaria no se puede obtener de la dirección ingresada. Por favor seleccione su zona horaria a continuación:',
    noDataToPlot: 'Sin datos',
    viewMore: 'Ver más',
    atleast4chars: 'Por favor, no escribas menos de 4 caracteres.',
    noMoreThan9: 'Por favor, no escribas mÃ¡s de 9 caracteres.',
    "noDataAvailable": "No hay datos disponibles",
    privacyPolicy: 'Privacy Policy',
    cameraUrlNotFound: "Your account is not registered in Camera portal. Please register in camera portal and try again.",
    yes: 'Yes',
    no: 'No',
    fleetopsUrlNotFound: "This feature is not configured for your account.",
    cameraTypeChangeWarning: 'You are changing camera type to {{camerType}}. This will reset the current camera serial number and password',
    max20Char: 'Maximum character limit exceeds.',
    max60Char: 'Maxmium 60 character allowed',
    onlyletterNumber: 'Only letters and numbers are allowed',
  },
  avatarMenu: {
    switchToOld: 'Cambiar a vista anterior',
    switchToNew: 'cambiar a nueva vista',
    messaging: 'Mensajería',
    logout: 'Cerrar sesión'
  },
  bulkDelete: {
    alertSuccess: 'Los datos se han importado con éxito. Estas cámaras han sido reasignadas.',
    deleteError: 'Ocurrió un error al eliminar',
    imported: 'Los datos se han importado con éxito.',
    deleteVehicle: '¿Desea eliminar los vehículos con los siguientes VIN?',
    allSuccess: 'Éxito',
    deleted: 'Following {{count}} vehicles are deleted.'
  },  
  leftMenu: {
    feedback: 'Comentarios',
    developerRequests: 'Solicitudes de desarrollador',
    reports: 'Reportes',
    IFTAReport: 'Informe IFTA',
    fuelReport: 'Reporte sobre el consumo de combustible',
    dailyDocuments: 'Documentos diarios',
    documents: 'Documentos',
    policiesProcedures: 'Políticas y procedimientos',
    DOTAudit: 'Auditoría DOT',
    USDOT: 'USDOT No. Gestión',
    companyData: 'Datos de la Compañia',
    filters: 'Filtros',
    inspections: 'Inspeciones',
    repairMaintenance: 'Reparos y Mantenimiento',
    accidents: 'Acidentes',
    vehicles: 'Vehículos',
    users: 'Usuarios',
    dashboard: "Panel de control",
    logs: 'Registros',
    csaViolation: 'Violaciones de CSA',
    camera: 'Cámara'
  },
  dashboard: {
    udNotification:
      'Tiene tiempo de conducci\u00f3n no identificado no asignado. Vaya a la pesta\u00f1a de registros y asigne estos registros al controlador correcto.',
    tabs: {
      driverBehavior: 'Comportamiento del conductor',
      vehicleStatus: 'Estado del Vehiculos',
      dailyLogs: {
        heading: 'Registros diarios'
      },
      driverStatus: {
        heading: 'Estado del conductor',
        lastKnownLocation: 'Última ubicación conocida',
        unit: 'Unidad',
        driveTimeRemaining: 'Tiempo de conducción restante',
        timeUntilBreak: 'Tiempo hasta el descanso',
        onDutyTimeRemaining: 'Tiempo restante de servicio',
        dutyCycleRemaining: 'Ciclo de trabajo restante',
        currentStatus: 'Estado actual',
        engineSpeed: 'La velocidad del motor',
        lastUpdated: 'Última actualización',
        eld: 'ELD'
      },
      potentialViolations: {
        heading: 'Posibles infracciones',
        pageHeading: 'GESTIONAR LAS VIOLACIONES',
        violationType: 'Tipo de infracción'
      },
      csaSnapshot: 'Instantánea CSA',
      recentAccidents: 'Accidentes recientes',
      developerRequests: 'Solicitudes de permiso de desarrollador',
      alerts: {
        enableNotification: 'Habilitar la notificación',
        heading: 'Alertas'
      }
    },
    header: {
      TOTALDRIVERS: 'Total de conductores activas',
      ONDUTY: 'De turno',
      DRIVING: 'Conducción',
      SLEEPER: 'Durmiente',
      OFFDUTY: 'Fuera de servicio',
      TOTALTRUCKS: 'Camiones totales',
      AVAILABLETRUCKS: 'Camiones Disponibles',
      INSHOP: 'En tienda',
      OUTOFSERVICE: 'Fuera de servicio'
    },
    driverStatus: {
      DRIVING: 'USUARIOS ADMINISTRACION - DRIVING',
      ON_DUTY: 'USUARIOS ADMINISTRACION - ON DUTY',
      SLEEPER_BERTH: 'USUARIOS ADMINISTRACION - SLEEPER',
      OFF_DUTY: 'USUARIOS ADMINISTRACION - OFF DUTY'
    },
    notInUseVehicles: {
      pageHeading: {
        INSHOP: 'ADMINISTRAR VEHÍCULOS - IN SHOP',
        OUTOFSERVICE: 'ADMINISTRAR VEHÍCULOS - OUT OF SERVICE'
      },
      listHeading: {
        INSHOP: 'List Of Vehicles In Shop',
        OUTOFSERVICE: 'List Of Vehicles Out Of Service'
      }
    },
    potentialViolations: {
      heading: 'Violaciones Potenciales',
      pageHeading: 'Notifications',
      violationType: 'Tipo de infracción'
    },
  },
  api: {
    errors: {
      noService: 'Servicio temporalmente no disponible',
      networkError: 'Error de servidor interno'
    }
  },
  chat: {
    sendMessage: 'Enviar un mensaje',
    selectDriver: 'A: Buscar...',
    newConvo: 'Nueva conversación',
    broad: 'Emisión',
    search: 'Buscar...',
    type: 'Escribe'
  },
  users: {
    listHeading: 'Administrar usuarios',
    addHeading: 'AGREGAR USUARIO',
    editHeading: 'Detalles del usuario',
    vehicleForMechanic: 'Vehículo para el mecánico',
    vehicleForDriver: 'Vehículo para el conductor',
    enableYardMoves: 'Habilitar movimientos de patio',
    enableNonDOTTrip: 'Habilitar viaje sin DOT',
    enablePersonalConveyance: 'Habilitar transporte personal',
    aGExemption: 'Exención de AG',
    exemptFromUsingELD: 'Exento de utilizar el ELD',
    expirationDate: 'Fecha de caducidad',
    selectFileToUpload: 'Seleccione el archivo para cargar',
    resetPasswordHeading: 'RESTABLECER LA CONTRASEÑA',
    profileHeading: 'EDITAR PERFIL',
    All: 'Todos',
    admins: 'Administradores',
    drivers: 'Conductores',
    mechanics: 'Mecanicos',
    driverStatusHistory: 'Historial de estado del conductor',
    fleetManager: 'Gestor de flota',
    joined: 'Unido',
    addNewUser: 'Agregar Usuario',
    quickFilter: 'Filtors Rapidos:',
    DVIROnly: "Solo DVIR",
  },
  logs: {
    manageLogsHeader: 'ADMINISTRAR REGISTROS',
    undefinedDriverHeader: 'REGISTROS DE CONDUCTORES NO IDENTIFICADOS',
    addLogHeader: 'AGREGAR REGISTROS',
    dailyLogExportMessage: 'Exportar el estado firmado del registro diario',
    logBook: 'Libro de registro',
    unassignedDriveTime: 'Tiempo de manejo no asignado',
    logs: 'Registros',
    unidentifiedDriverProfile: 'Perfil del conductor no identificado',
    dateInUtc: 'Fecha (en UTC)',
    viewDocument: 'VER DOCUMENTO',
    noViolation: 'No hay datos de violación para el día',
    history: 'Historial de Registro Diario',
    editHeading: 'EDITAR REGISTRO DIARIO',
    addHeader: 'Información del usuario',
    codriver: 'Nombre del copiloto',
    logLocation: 'Ubicación de firma de registro',
    violations: 'Violaciones',
    status: 'Estado',
    stateModifier: 'Modificador de estado',
    timeFrom: 'Tiempo desde',
    timeTo: 'Tiempo hasta',
    location: 'Ubicación',
    assignedDriver: 'Conductor asignado',
    notes: 'Notas',
    addNewRow: 'Agregar nueva fila',
    Submit: 'Guardar',
    UpdateEvents: 'Actualizar eventos',
    UpdateGraph: 'Actualizar gráfico',
    noDataMessage: 'Solicitud no válida. Haga clic aquí para volver al panel',
    backToDashboard: 'regresa',
    viewDocument: 'Ver documentos',
    archiveLogsHeader: 'REGISTROS ARCHIVAAOS', // Traslated
    manageLogsHeaderSigned: 'Registros Firmados', // Translated
    manageLogsHeaderUnSigned: 'Registros en tiempo real/No asignados', // Translated
    allDutyTimeTracker: 'Informe de seguimiento de todo el tiempo de servicio,' // Translated
  },
  vehicles: {
    addHeading: 'AÑADIR VEHÍCULO',
    editHeading: 'Editar vehículo',
    fullName: 'Nombre completo',
    role: 'Función',
    email: 'Correo Electrónico',
    empId: 'Emp Id',
    assignedVehicles: 'Vehículos asignados',
    lastLogin: 'Última conexión',
    users: 'Usuarios',
    searchUser: 'Buscar usuario',
    importUserTemplate: 'Importar usuario',
    exportUser: 'Exportar usuarios',
    deactivateUser: '¡Sí, desactivar usuario!',
    activateIt: '¡Sí, actívalo!',
    listHeading: 'ADMINISTRAR VEHÍCULOS',
    searchVehicle: 'Buscar Vehículo',
    searchVehicleTrailer: 'Buscar Vehículo / remolque',
    unitId: 'Identificación de la unidad',
    drivers: 'Conductores',
    type: 'Tipo',
    vin: 'VIN',
    year: 'Año',
    manufacturer: 'Fabricante',
    model: 'Modelo',
    tabs: {
      listVehicles: 'Lista de Vehículos',
      listTrailers: 'Listado de remolques',
      roadInspection: 'Inspecciones en el camino'
    },
    addVehicle: 'Agregar vehiculo',
    addTrailer: 'Agregar remolque',
    importVehicleTemplate: 'Plantilla de Vehiculoss de importación',
    importTrailerTemplate: 'Plantilla de importación de trailers',
    trackVehicle: 'Seguimiento del vehículo',
    dateTimeFrom: 'Fecha/Tiempo desde',
    dateTimeTo: 'Fecha/Hora de',
    exportVehicle: 'Exportar Vehículos',
    exportTrailer: 'Exportar trailers',
    seatBeltStatus: 'Estado del cinturón de seguridad',
    vehicleType: 'Tipo de vehículo',
    importVeh: 'Importar vehículos',
    importTra: 'Importar tráilers',
    mechanics: 'Mecánica'
  },
  reports: {
    downloadReports: 'Descargar reportes'
  },
  accidents: {
    heading: 'DIRIGIR LOS ACCIDENTES',
    witnessStatement: 'Declaracion de testigo',
    accidentReport: 'Reporte de accidente',
    dateAndTime: 'Fecha y hora',
    viewWitness: 'Ver Testigos',
    viewReport: 'Vista del informe',
    viewReceipt: 'Ver recibos'
  },
  eld: {
    all: 'Todas',
    delivery_receipts: 'Recibo de entrega',
    dispatch_records: 'Dispatch Record',
    bills_of_landing: 'Guía de carga',
    receipts: 'Copia del recibo',
    weigh_tickets: 'Boleto de pesaje',
    communication_records: 'Registro de comunicación',
    other_documents: 'Otro documento'
  },
  documents: {
    listHeading: 'Documentos'
  },
  developerRequests: {
    listHeading: 'Solicitudes de permiso de desarrollador',
    developerName: 'Nombre del desarrollador'
  },
  departments: {
    terminalsAndDepartments: 'TERMINALES Y DEPARTAMENTOS',
    noLocations: 'Ninguna ubicación agregada',
    noDepartments: 'No hay departamentos bajo esta terminal',
    addTerminal: 'Agregar terminal',
    editTerminal: 'Editar terminal',
    addRegion: 'Agregar región',
    editRegion: 'Editar región',
    regionName: 'Nombre de la región',
    fleetManagers: 'Gestor de flota',
    dot: 'Dot #',
    addDepartment: 'Agregar departamento',
    editDepartment: 'Editar departamento',
    addLocation: 'Añade una ubicación',
    editLocation: 'Editar ubicación'
  },
  registration: {
    companyInfoHeading: 'INFORMACIÓN SOBRE LA COMPAÑÍA',
    userInfoHeading: 'INFORMACIÓN DEL ADMINISTRADOR DE LA FLOTA',
    initialTrucks: 'Número de vehículos',
    maintenancePeriod: 'Período de mantenimiento (meses)',
    maintenanceMileage: 'Mantenimiento de Millaje',
    subscriptionTier: 'Nivel de suscripción',
    contractLength: 'Duración del contrato (años)',
    createAccount: 'Crear una cuenta',
    producerCode: 'Código del productor',
    iAgree: 'Estoy de acuerdo con la',
    termsOfUse: 'Términos de Uso',
    totalAmount: 'Cantidad total:$',
    paymentConfirmation: 'CONFIRMACIÓN DE PAGO',
    noOfTrucks: 'Cantidad de camiones',
    costperTruck: 'Costo por camión ($)',
    salesTax: 'Sales Tax ($)',
    discount: 'Descuento (%) (si corresponde)',
    total: 'Cantidad total ($)',
    confirm: 'Confirmar',
    accept: 'Aceptar',
    iAcceptAll: 'Acepto los siguientes términos y condiciones.'
  },
  csaViolation: {
    listHeading: 'VIOLACIONES DE CSA',
    dvirLink: 'Enlace DVIR',
    dvirStatus: 'Estado de DVIR',
    roadsideDot: 'Inspección DOT en carretera',
    workOrder: 'Orden de trabajo',
    workOrderStatus: 'Estado de la orden de trabajo',
    oos: 'OOS',
    severity: 'Gravedad'
  },
  iftaMileage: {
    listHeading: 'KILOMETRAJE IFTA',
    search: 'Buscar',
    notLessThanZero: 'Meter reading should not be less than zero',
    tooltip: 'Descargar Truck GPS history',
    hideLabel: 'Ocultar estados sin kilometraje',
    activeVehicles: 'Vehículos activos',
    deactivateVehicles: 'Vehículos desactivados',
    odometer: 'Calcule desde el odómetro',
    totalMiles: 'Millas totales',
    totalTaxableMiles: 'millas totales sujetas a impuestos',
    truck: 'Camión',
    Jurisdiction: 'Jurisdicción'
  },
  fuelConsumption: {
    pageHeading: 'REPORTE SOBRE EL CONSUMO DE COMBUSTIBLE',
    search: 'Informe de búsqueda por vehículo',
    currentDrivers: 'Conductores Actuales',
    location: 'La última ubicación de abastecimiento de combustible',
    Jurisdiction: 'Jurisdicción',
    miles: 'Millas por galón',
    history: 'Historial de recargas',
    unknown: 'Desconocido',
  },
  refillsHistory: {
    pageHeading: 'HISTORIA DE RECAMBIOS',
    odometer: 'Odómetro',
    Jurisdiction: 'Jurisdicción',
    gallons: 'Galones',
    fuelAmount: 'Cantidad de combustible (Galones)',
    cost: 'Costo total',
  },
  usDotManagement: {
    pageHeading: 'PÁGINA DE GESTIÓN DE NÚMERO DE USDOT',
    search: 'Buscar USDOT No',
    usDotNo: 'USDot No.',
    carrierName: 'Nombre del operador',
    mainAddress: 'Dirección principal de la oficina',
    zip: 'Código postal',
    addDot: 'AGREGAR NUEVO DOT',
    editDot: 'EDITAR DOT',
    message: 'Mensaje',
    send: 'Enviar',
  },
  inspections: {
    listHeading: 'Gestionar inspecciones',
    user: 'Búsqueda por usuario',
    vehicle: 'Buscar por vehículo',
    inspectionType: 'Tipo de inspección',
    inspectionReport: 'Reporte de inspección',
    advancedDvir: 'DVIR avanzado',
    bulkDownloadReport: 'Informes de descargas masivas',
    dvirError: 'Configuration type or section name not entered',
    viewReport: 'Vista del informe',
    customDvir: 'DVIR personalizado',
    hasDefects: "Has Defects?",
    // addNewVehicleForm: "Agregar un nuevo formulario de vehículo",
    // addNewTrailerForm: "Añadir un nuevo formulario de tráiler",
  },
  companyData: {
    pageHeading: 'DATOS DE LA COMPAÑIA',
    companyName: 'Compañía',
    maintainancePeriod: 'Periodo de Mantenimiento',
    maintainancePeriodMonth: 'Periodo de Mantenimiento (meses)',
    maintainanceMileage: 'Mantenimiento de Millaje',
    contactPerson: 'Persona de Contacto',
    noOfTrucks: 'Número de vehículos',
    homeTimeZone: 'Zona Horaria Local',
    otherLocationContactPerson: 'Otra persona de contacto de ubicación',
    otherLocationAddress: 'Otra dirección de ubicación',
    otherLocationPhoneNumber: 'Otro número de teléfono de la ubicación',
    website: 'sitio web',
    exceptions: 'Excepciones',
    defaultCycleRule: 'Regla de ciclo predeterminada',
    vehicleType: 'Tipo de vehículo',
    updateCardDetails: 'Actualizar detalles de la tarjeta',
    paymentInformation: 'Información del pago',
    cancelSubscription: 'Cancelar suscripción',
    updateCreditCardDetails: 'Actualizar los detalles de la tarjeta de crédito',
    contractExpirationDate: 'Fecha de Vencimiento de Contrato',
    currentSubscriptionCost: 'Costo de Suscripción Actual',
    skipcompanyCheckbox: 'Omitir búsqueda de filtro de vehículo',
    enablecompanyCheckbox: 'Habilitar campo petrolífero en espera'
  },
  paymentInformation: {
    listHeading: 'DETALLES DEL PAGO',
    contractExpiry: 'FECHA DE EXPIRACIÓN DE CONTRATO',
    companyInfo: 'Company Info for ',
    contractHistory: 'Historia del contrato',
    startDate: 'Fecha de inicio del contrato',
    contractLength: 'Duración del contrato',
    nextBilling: 'Siguiente Fecha de facturación',
    paymentHistory: 'Payment History for ',
    createdDate: 'Fecha de creación',
    paidDate: 'Fecha de pago',
    unitCount: 'Unidad de cuenta',
    cost: 'Costo por unidad',
    receipt: 'Recibo de pago',
    start: 'Fecha de estado',
    end: 'Fecha final',
    noOfDays: 'No. de días',
    status: 'Estado de pago',
    company: 'Nombre de Empresa'
  },
  workOrders: {
    search: 'busqueda de orden de trabajo',
    pageHeading: 'DIRIGIR LAS ORDENES DE TRABAJO',
    workOrderNo: 'Orden de trabajo',
    reportedDate: 'Fecha reportada',
    scheduledDate: 'Programado Fecha',
    dueDate: 'Fecha de vencimiento',
    repairCost: 'Costo de Reparo ($)',
    inspectionReport: 'INFORMES DE INSPECCIÓN',
    serviceType: 'Tipo de Servicio',
    open: 'Open WorkOrders',
    closed: 'Closed WorkOrders',
    addWorkOrders: 'Agregar orden de trabajo',
    manageParts: 'Administrar piezas',
    driver: 'Driver',
    unitType: 'Unit Type',
    addHeading: 'DETALLES DE LA ORDEN DE TRABAJO',
    viewReport: 'Vista del informe',
    noReport: 'No informe',
    status: 'ESTADO',
    inspectionDetails: 'DETALLES DE INSPECCIÓN',
    concern: 'Preocupación',
    cause: 'Porque',
    correction: 'Corrección',
    receipts: 'INGRESOS',
    laborCost: 'Costo laboral ($)',
    partsCost: 'Costo ($)',
    totalCost: 'Costo ($)',
    mechanic: 'Mecanicos',
    scheduled: 'Programado',
    notes: 'Notas',
    viewPhotos: 'ver fotos',
    viewInspectionreport: 'ver informe de inspeccion'
  },
  suspended: {
    listHeading: 'SUSCRIPCIÓN',
    buttonText: 'Pagar con tarjeta',
    title: 'DETALLES DEL PAGO',
    description: 'Tienes un contrato activo de',
    to: 'hasta',
    payButtonText: 'AÑADIR DETALLES DE LA TARJETA',
    'expiredContract': 'Su contrato ha expirado.',
    saveButtonText: 'Save',
  },
  policies: {
    heading: 'Políticas y procedimientos',
    mainInfo: 'información principal',
    selectPolicies: 'Seleccionar políticas',
    companyLogo: 'Logotipo de la compañía',
    chooseImage: 'Por favor, elija un archivo .png o .jpg',
    DRESS_CODE: 'Código de vestimenta',
    INSPECTION: 'Inspección',
    PSP_USAGE: 'Uso de la PSP',
    LOSS_CONTROL_MANUAL_POLICY: 'Manual de control de pérdidas',
    DRIVER_SELECTION_MVR: 'Conformidad con el historial de manejo',
    DRIVING_TRAINING: 'Formación de conductores',
    ACCIDENT_INVESTIGATION: 'Investigación de accidentes',
    DISTRACTION_FREE_DRIVING: 'Conducción sin distracciones',
    MIN_DRIVER_AGE: 'Edad mínima del conductor permitida',
    MIN_YEARS_ON_SIMILAR_EQUIPMENT: 'Mínimo de años en equipos similares',
    NUMBER_OF_ACCIDENTS_ALLOWED: 'Número máximo de accidentes',
    NUMBER_OF_CITATIONS_ALLOWED: 'Número máximo de citaciones permitido',
    MIN_HOURS: 'Horas de formación obligatorias al año',
    TRAINING_DAYS: 'Días de formación',
    SAFETY_PERSON_FULL_NAME: 'Nombre completo del responsable de seguridad',
    ALLOW_USING_PHONES: 'Permitir el uso de Bluetooth',
    CUSTOM_POLICY: "política personalizada" // Translated
  },
  dailyDocuments: {
    receipt: 'Copia del recibo',
    users: 'Usuarios',
    documentType: 'Tipo de Documento',
    cusName: 'Cliente/Nombre del trabajo',
    uploadNewDoc: 'Subir nuevo documento'
  },
  popup: {
    success: 'Éxito'
  },
  roadInspection: {
    driverId: 'Identificación del conductor',
    defectFound: 'Defecto encontrado'
  },
  parts: {
    pageHeading: 'Manage Parts',
    partName: 'Parte',
    partsInventory: 'En inventario',
    cost: 'Costo ($)',
    addPart: 'Agregara pieza',
    partTitle: 'Piezas',
    costError: 'Ingrese un valor mayor o igual a 0'
  },
  reportPage: {
    dutyStatus: 'Estado del servicio',
    driverPerf: 'Reporte del rendimiento del conductor',
    editedData: 'Reporte de datos editados',
    rejectedEdits: 'Ediciones rechazadas',
    drivingEvents: 'Reporte de evento de manejo',
    eldDisconn: 'Reporte de desconexión del ELD',
    odoJump: 'Reporte de salto de cuentakilómetros',
    malFunc: 'Reporte de avería',
    unassigned: 'Reporte de manejo no asignado',
    violation: 'Reporte de infracciones',
    hos: 'Detalle de las infracciones de las horas de servicio',
    billOfLading: 'Reporte sobre el conocimiento de embarque',
    DailyHocDriverViolation: "Reporte sobre las infracciones de las horas de servicio",
    dailyUnsignedLogReport: "Reporte de registro sin firma",
    DailyFailedInspectionReport: "Reporte de inspección fallido",
    DailyUnidentifiedDrivingReport: "Reporte de conducción no identificado",
    MonthlyHOSDriverViolationsReport: "Reporte mensual de infracciones de las horas de servicio de los conductores",
    MonthlyUnsignedLogReport: "Reporte mensual de registro sin firma",
    bayIndustriesPayroll: "Informe de nómina", // Translated
    allDutyTimeTracker: "Informe de seguimiento de todo el tiempo de servicio", // Translated
  },
  potentialViolations: {
    heading: 'Violaciones Potenciales',
    pageHeading: 'Gestionar las infracciones',
    violationType: 'Tipo de infracción'
  },
  trailers: {
    deleteIt: '¡Sí, elimínalo!',
    recoverConfirm: 'No podra recuperar este trailer',
    trailerType: 'Tipo de remolque',
    addHeading: 'Agregar remolque',
    editHeading: 'Editar tráiler'
  },
  graphTitles: {
    hardAcceleration: 'Aceleración dura',
    hardDeceleration: 'desaceleración dura',
    speeding: 'Exceso de velocidad',
    hoursExceeding: 'Horas de conducción excedidas',
    fuelEconomy: 'Economía de combustible',
    nonBuckledSeatbelt: 'Advertencia de cinturón de seguridad no abrochado',
    hardTurn: 'Giro dura',
  },
  "selectWithDot": "Seleccionar...",
  "newScoreCard": {
      "heading": "Resumen de la Tarjeta de Puntuación",
      "selectPeriod": "Seleccionar Período",
      "unpoweredDays": "Días Sin Funcionamiento",
      "driverBehavior": "Comportamiento del Conductor",
      "fleetScore": "Puntuación de la Flota",
      "radiusViolation": "Violación del Radio",
      "distractedDriving": "Conducción Distraída",
      "speeding": "Exceso de Velocidad",
      "contact": "Contacto",
      "totalVehicle": "Total de Vehículos"
    },
    "scorecard": {
      "driverBehavior": "Comportamiento del Conductor",
      "unpoweredDays": "Días Sin Funcionamiento",
      "unpoweredDaysDescription": "Basado en el número de días en que los camiones no estaban en funcionamiento. Menos días sin funcionamiento resultan en una puntuación más alta.<br/><br/><i>Método: 0.2 * Puntuación de Días Sin Funcionamiento</i>",
      "driverBehaviorDescription": "Calculado en base al número de eventos de conducción. La puntuación representa un comportamiento de conducción seguro, con menos eventos que llevan a una puntuación más alta.<br/><br/><i>Método: 0.6 * Puntuación de Comportamiento del Conductor</i>",
      "radiusViolations": "Violaciones del Radio",
      "radiusViolationsDesc": "Mide los días en que los camiones violaron el radio predefinido. Menos días de violación resultan en una puntuación más alta.<br/><br/><i>Método: 0.2 * Puntuación de Violación del Radio</i>",
      "total": "Total",
      "vehicle": "Vehículos",
      "driverBehaviorDesc": "Calculado en base al número de eventos de conducción en todos los camiones.<br/><br/><i>Método: 100 - (Suma de Eventos de todos los camiones / (Días Laborales Totales * Total de Camiones)) * 100.</i><br/><br />El gráfico circular muestra el número de camiones agrupados por sus porcentajes de rendimiento individuales, indicando cuántos camiones caen dentro de cada rango definido.",
      "actualScore": "Puntuación Actual",
      "distractedDriving": "Conducción Distraída",
      "accident": "Accidente",
      "speeding": "Exceso de Velocidad",
      "moreInfo": "Más Información",
      "unpoweredDaysGraphDesc": "Calculado en base al número de días en que los camiones no estaban en funcionamiento.<br/><br/><i>Método: 100 - (Suma de Días Sin Funcionamiento de cada camión / (Días Laborales Totales * Total de Camiones)) * 100.</i><br/><br />El gráfico circular muestra el número de camiones agrupados por sus porcentajes de rendimiento individuales, indicando cuántos camiones caen dentro de cada rango definido.",
      "unpoweredDaysTotalDesc": "El número acumulativo de días sin funcionamiento en todos los camiones de la flota",
      "radiusViolationGraphDesc": "Calculado en base al número de días en que los camiones violaron el radio predefinido.<br/><br/><i>Método: 100 - (Suma de Días de Violación de cada camión / (Días Laborales Totales * Total de Camiones)) * 100.</i><br/><br />El gráfico circular muestra el número de camiones agrupados por sus porcentajes de rendimiento individuales, indicando cuántos camiones caen dentro de cada rango definido.",
      "radiusViolationTotalDesc": "El número acumulativo de días de violación del radio en todos los camiones de la flota",
      "noDataText": "¡No hay datos disponibles!",
      "excellent": "Excelente",
      "good": "Bueno",
      "average": "Promedio",
      "belowAverage": "Por Debajo del Promedio"
    },
    banner: {
      tellUs: '¡Cuéntanos más sobre tu flota!',
      haveCameraQ: '¿Tienes cámaras?',
      haveInsuranceQ: '¿Quién es tu proveedor de seguros?',
      yes: 'Sí',
      no: 'No',
      cameraProviderQ: '¿Quién es tu proveedor de cámaras?',
      policyRenewQ: '¿Cuándo se renueva tu póliza?',
      submit: 'Enviar',
      later: 'Más tarde',
      thankYou: '¡Gracias por tu respuesta!',
      thankYouInfo: 'Esta ventana desaparecerá en {{ count }} segundos',
      close: 'Cerrar',
      email: "Dirección de correo electrónico",
      phone: "Número de teléfono",
      emailValidation: 'Email must be a valid',
      cameraProviderPlaceholder: "Por favor ingrese el nombre del proveedor de cámaras",
      insuranceProviderPlaceholder: "Por favor ingrese el nombre de su proveedor de seguros (opcional)",
      emailPlaceholder: "ejemplo@gmail.com",
      phoneNumberPlaceHolder: "000-000-0000"
    }
};
