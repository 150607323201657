
const initialState = {};

export const gigBanner = {
  state: { ...initialState },
  reducers: {},
  effects: (dispatch) => ({
    async fetchGiGBanner(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('fetchGigBannerApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
            },
          },
          id: payload.apiId
        });
        if (response) {
          if (response?.status === 200 && response?.data?.data?.showPopup === true) {
            payload.callback(true, response);
            return;
          }
          payload.callback(false, response);
        }
      } catch (error) {
        payload.callback(false, null, error?.message);
      }
    },
    async saveGigBanner(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveGigBanner');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: apiObject.url
            },
            data: payload.data
          },
          id: payload.apiId
        });
        if (response?.status === 207) {
          payload.callback(false, null, response?.data?.errors[0]);
          return;
        }
        response && payload.callback(true, response);
      } catch (error) {
        payload.callback(false, null, error?.message);
      }
    },
  })
};
