import PropTypes from 'prop-types';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  COOKIE_AUTH_DETAILS,
  pageUrls
} from '../config/constants/keys';
import { AuthProvider } from '../services/auth';
import { getCookie } from '../services/cookie';
import {
  findListItem,
  getAuthDetails,
  isValidObject
} from '../utils/utils';

import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;

const CautionIcon = lazy(() => import(`@/components/CautionIcon`));
const Department = lazy(() =>
  import(`../components/modules/Department/Wrapper`)
);
const AssignmentIcon = lazy(() =>
  import(`../components/modules/QuestionnaireBanner/AssignmentIcon`)
);

const Modal = lazy(() => import(`../components/UI/Modal/${layout}`));

const Routes = ({ history, location, folderPath, handleLogin }) => {
  const [auth, setAuth] = useState(getAuthDetails());
  const [resetPopupOpen, setResetPopupOpen] = React.useState(false);
  const [filterPopupOpen, setFilterPopupOpen] = React.useState(false);

  useEffect(() => {
    const cookie = getAuthDetails();
    const isNotValidCookie = !isValidObject(cookie);
    const isNotAuthenticated = !auth?.auth;
    if (isNotAuthenticated || isNotValidCookie) {
      setAuth(cookie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state, auth]);

  const ADMIN = '/admin/';
  const ADMIN_LOGIN = '/admin/login';
  const WEB_LOGIN = pageUrls.login;
  const url = folderPath === ADMIN ? ADMIN_LOGIN : WEB_LOGIN;
  const basePath = `.${folderPath}${window?.$environment?.SERVICE_PROVIDER}`;
  const getConfig = (file) => {
    try {
      const config = require(`${basePath}/${file}`).default;
      return config;
    } catch (error) {
      return [];
    }
  };
  const privateConfig = getConfig('privateConfig');
  const publicConfig = getConfig('publicConfig');
  const commonConfig = getConfig('commonConfig');

  const setFilterPopup = () => {
    setFilterPopupOpen(true);
  };


  // notication tab for gig popup disbaled
  // const getGigInfo = () => {
  //   if (!showBanner
  //       && localStorage.getItem('bannerDate')
  //       && localStorage.getItem('bannerDate') !== "submitted"
  //       && auth?.auth && hasRestrictedRole(['FLMANAGER'])
  //       && (auth?.showBanner === null || auth?.showBanner === true)
  //     && moment(moment()).diff(localStorage.getItem('bannerDate'), 'seconds') < BANNER_VALIDATOR_TIME_IN_S) {
  //       return true;
  //   }
  //   return false;
  // }

  useEffect(() => {
    const isAuthenticated = auth?.auth;
    const currentPath = window.location.pathname;

    if (isAuthenticated && currentPath === pageUrls.resetPassword) {
      setResetPopupOpen(true);
    } else {
      setResetPopupOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const publicRoutes = publicConfig.map((config, key) => {
    return (
      <Route
        exact
        path={config.url}
        render={() => {
          const ifLayouPlain = config?.layout === 'plain';
          const AsyncComponent =
            (ifLayouPlain &&
              lazy(() => {
                return import(`../pages${folderPath}${config.component}`);
              })) ||
            null;
          const isComponent = isValidObject(AsyncComponent);
          return isComponent ? (
            <Suspense fallback={null}>
              <AsyncComponent history={history} pageTitle={config.title} />
            </Suspense>
          ) : (
            <RenderComponent
              history={history}
              setAuth={setAuth}
              config={config}
              setFilterPopup={setFilterPopup}
              folderPath={folderPath}
            />
          );
        }}
        key={key}
      />
    );
  });

  const privateRoutes = privateConfig.map((config, key) => {
    if (location.state?.error === 'permission denied') {
      return (
        <Route
          path={config.url}
          render={() => (
            <RenderComponent
              history={history}
              setAuth={setAuth}
              config={{
                title: 'Permission Denied',
                component: 'Unhandled'
              }}
              setFilterPopup={setFilterPopup}
              folderPath={folderPath}
              message={() => (
                <div className="PermissionChanges">
                  <CautionIcon noButton message={'Permission Denied'} />
                </div>
              )}
              permissionDenied
            />
          )}
        />
      );
    } else if (
      //WLs Other than spireon
      (auth?.auth && window.$environment.SERVICE_PROVIDER !== 'spireon') ||
      (auth?.auth &&
        window.$environment.SERVICE_PROVIDER === 'spireon' &&
        !history.location.pathname.includes('/manager/login') &&
        !history.location.pathname.includes('/auth/login') &&
        !history.location.search.includes('token'))
    ) {
      const isAuthorized = findListItem(
        config.roles,
        auth.auth,
        [config.module, ...(config.dependency || '')],
        auth.enterpriseFeatureDto
      );
      const isTierEnabled =
      (auth.subscriptions?.[config.subscriptions] !== undefined &&
        auth.subscriptions?.[config.subscriptions]) ||
      (auth.enterpriseFeatureDto?.[config.module] !== undefined &&
        auth.enterpriseFeatureDto?.[config.module])
        ? true
        : (auth.subscriptions?.[config.subscriptions] === undefined &&
            auth.enterpriseFeatureDto?.[config.module] === undefined)
        ? true
        : false;
      if (auth.adminSuspended) {
        if (config.url === '/user/profile') {
          return (
            <Route
              exact
              path={config.url}
              key={key}
              render={() => {
                return (
                  <RenderComponent
                    history={history}
                    setAuth={setAuth}
                    config={config}
                    setFilterPopup={setFilterPopup}
                    folderPath={folderPath}
                    layout={config.layout}
                  />
                );
              }}
            />
          );
        } else
          return (
            <Route
              path={config.url}
              render={() => (
                <RenderComponent
                  history={history}
                  setAuth={setAuth}
                  config={{
                    title: config.title,
                    component: 'Unhandled',
                    layout: 'authenticatedStyle'
                  }}
                  setFilterPopup={setFilterPopup}
                  folderPath={folderPath}
                  message=""
                  isPopUp
                />
              )}
            />
          );
      } else if (!isTierEnabled) {
        return (
          <Route
            path={config.url}
            render={() => (
              <Route
                path={config.url}
                render={() => (
                  <RenderComponent
                    history={history}
                    setAuth={setAuth}
                    config={{
                      title: 'Upgrade Subscription',
                      component: 'SubscriptionMessage'
                    }}
                    setFilterPopup={setFilterPopup}
                    folderPath={folderPath}
                  />
                )}
              />
            )}
          />
        );
      } else if (isAuthorized) {
          return (
            <Route
              exact
              path={config.url}
              key={key}
              render={() => {
                return (
                  <RenderComponent
                    history={history}
                    setAuth={setAuth}
                    config={config}
                    setFilterPopup={setFilterPopup}
                    folderPath={folderPath}
                    layout={config.layout}
                  />
                );
              }}
            />
          );
      } else {
        return (
          <Route
            path={config.url}
            render={() => (
              <RenderComponent
                history={history}
                setAuth={setAuth}
                config={{
                  title: 'Permission Denied',
                  component: 'Unhandled'
                }}
                setFilterPopup={setFilterPopup}
                folderPath={folderPath}
                message={() => (
                  <div className="PermissionChanges">
                    <CautionIcon noButton message={'Permission Denied'} />
                  </div>
                )}
                permissionDenied
              />
            )}
          />
        );
      }
    } else {
      return (
        <Redirect
        to={{
          pathname: url,
          ...(location?.search ? {
            search: `redirect=${location?.pathname}${location?.search}`
          } : {}),
          state: {
            ...location.state,
            referrer: url !== location.pathname ? location.pathname : '',
            search:
              location.pathname !== pageUrls.resetPassword
                ? location.search
                : ''
          }
        }}
          key={key}
        />
      );
    }
  });

  const commonRoutes = commonConfig?.map((config, key) => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    if (!token) {
      config['layout'] = 'authenticatedWithoutMenu';
    }
    if (auth?.auth) {
      const isAuthorized = findListItem(
        config.roles,
        auth.auth,
        [config.module, ...(config.dependency || '')],
        auth.enterpriseFeatureDto
      );
      const isTierEnabled = auth.subscriptions?.[config.subscriptions] || true;
      if (isAuthorized) {
        if (!isTierEnabled) {
          return (
            <Route
              path={config.url}
              render={() => (
                <RenderComponent
                  history={history}
                  setAuth={setAuth}
                  config={{
                    title: 'Upgrade Subscription',
                    component: 'SubscriptionMessage'
                  }}
                  setFilterPopup={setFilterPopup}
                  folderPath={folderPath}
                />
              )}
            />
          );
        } else
          return (
            <Route
              exact
              path={config.url}
              key={key}
              render={() => {
                return (
                  <RenderComponent
                    history={history}
                    setAuth={setAuth}
                    config={config}
                    setFilterPopup={setFilterPopup}
                    folderPath={folderPath}
                    layout={config.layout}
                  />
                );
              }}
            />
          );
      }
    } else {
      return (
        <Route
          exact
          path={config.url}
          render={() => {
            const AsyncComponent =
              lazy(() => {
                return import(`../pages${folderPath}${config.component}`);
              }) || null;
            const isComponent = isValidObject(AsyncComponent);
            return isComponent ? (
              <RenderComponent
                config={config}
                folderPath={folderPath}
                layout={'authenticatedWithoutMenu'}
              />
            ) : (
              <RenderComponent
                history={history}
                setAuth={setAuth}
                config={config}
                setFilterPopup={setFilterPopup}
                folderPath={folderPath}
              />
            );
          }}
          key={key}
        />
      );
    }
  });


  return (
    <AuthProvider value={{ ...auth }}>
      <Switch>
        {commonRoutes}
        {publicRoutes}
        {privateRoutes}
        <Route
          path={window.location.pathname}
          render={() => (
            <RenderComponent
              history={history}
              setAuth={setAuth}
              config={{ title: 'Page not found', component: 'Unhandled' }}
              setFilterPopup={setFilterPopup}
              folderPath={folderPath}
              message=""
            />
          )}
        />
        <Redirect to={window.location.pathname} />
      </Switch>

      <Modal
        open={resetPopupOpen}
        setOpen={setResetPopupOpen}
        data={{
          header: '',
          content: () => (
            <>
              <CautionIcon />
            </>
          )
        }}
        isresets
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />

      <Modal
        open={filterPopupOpen}
        setOpen={setFilterPopupOpen}
        data={{
          header: '',
          content: () => <Department action={() => setFilterPopupOpen(false)} />
        }}
        background="black"
        withCloseIcon
      />
      {/* {getGigInfo() && <AssignmentIcon open={() => openGigBanner()} />} */}
    </AuthProvider>
  );
};
export default Routes;

Routes.defaultProps = {
  children: null
};
Routes.propTypes = {
  location: PropTypes.object.isRequired,
  folderPath: PropTypes.string.isRequired,
  children: PropTypes.element,
  history: PropTypes.object.isRequired
};
