import { getStyle } from '@/utils/utils';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  ${({ theme }) => `
    .floating_icon {
        position: fixed;
        bottom: 20px;
        left: 20px;
        font-size: 40px;
        animation: float 5s ease-in-out infinite, bounce 1s infinite alternate;
        z-index: 9999;
    }
  
    .floating_icon .MuiIconButton-root {
        color: ${getStyle('', (theme.palette.secondary || {}).main, theme.colors.PRIMARY_MAIN)};
        cursor: pointer;
    }

    .floating_icon:hover .MuiIconButton-root {
        transform: scale(1.2);
        color:  ${getStyle('', (theme.palette.secondary || {}).main, theme.colors.PRIMARY_MAIN )};
    }
  
    @keyframes float {
        0% {
        transform: translateY(0);
        }
        50% {
        transform: translateY(-15px);
        }
        100% {
        transform: translateY(0);
        }
    }
  
    @keyframes bounce {
        0% {
        transform: translateY(0);
        }
        50% {
        transform: translateY(-10px);
        }
        100% {
        transform: translateY(0);
        }
    }
  `}
`;



const Styled = {
    Wrapper,
};
export default Styled;
