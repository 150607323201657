import { get } from 'lodash';
import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const UploadList = lazy(() =>
    import(`@/components/modules/DocumentUploadList`)
);

const UploadModule = (props) => {
    const {
        callback,
        viewOnly,
        type,
        userId,
        values,
        uploadList,
        setUploadList,
        setIsDocumentAvailable
    } = props;
    const dispatch = useDispatch();
    const apiStatus = useSelector(state => state.asyncRequests);
    const logBook = useSelector(state => state.logBook);
    const documents = useSelector(state => type === "add" ? state.user?.documentsList : state.userEdit?.documentsList);
    const userDocument = useSelector(state => state.userDocument?.documentsList);

    useEffect(() => {
        if (get(documents?.length) === 0) return;
        dispatch.userDocument.updateIntialValue(documents);
    }, [documents, dispatch]);  

    useEffect(() => {
        if (userDocument) {
          let driverDocs = [];
          let mechanicDocs = [];
          let commonDocs = [];
          for (let i = 0; i < userDocument.length; i++) {
            const item = userDocument[i];
            driverDocs.push(item);
            if (
              item.doctype === 'COMMON' ||
              item.doctype === 'OTHER' ||
              item.doctype === 'POLICY'
            ) {
              commonDocs.push(item);
            } else if (
              item.doctype !== 'DRIVER' &&
              item.doctype.includes('MECHANIC') > -1
            ) {
              mechanicDocs.push(item);
            }
          }
          if (values.driverRole) {
            setUploadList({
              driverDocs,
              mechanicDocs,
              commonDocs,
              currentDocs: [...driverDocs]
            });
          } else if (!values.driverRole && values.mechanicRole) {
            setUploadList({
              driverDocs,
              mechanicDocs,
              commonDocs,
              currentDocs: [
                ...commonDocs.slice(0, 1),
                ...mechanicDocs,
                ...commonDocs.slice(1)
              ]
            });
          } else {
            setUploadList({
              driverDocs,
              mechanicDocs,
              commonDocs,
              currentDocs: [...commonDocs]
            });
          }
          const noDocuments = uploadList.currentDocs.some(o => o.id)
          setIsDocumentAvailable(noDocuments)
        }
      }, [values.driverRole, values.mechanicRole, userDocument]);

      useEffect(() => {
        if (uploadList?.currentDocs?.length) {
          if (values.driverRole) {
            setUploadList({
              ...uploadList,
              currentDocs: [...uploadList.driverDocs]
            });
          } else if (!values.driverRole && values.mechanicRole) {
            setUploadList({
              ...uploadList,
              currentDocs: [
                ...uploadList.commonDocs.slice(0, 1),
                ...uploadList.mechanicDocs,
                ...uploadList.commonDocs.slice(1)
              ]
            });
          } else if (!values.driverRole && !values.mechanicRole) {
            setUploadList({
              ...uploadList,
              currentDocs: [...uploadList.commonDocs]
            });
          }
        }
      }, [values.driverRole, values.mechanicRole]);
    
      useEffect(() => {
        const noDocuments = uploadList.currentDocs.some(o => o.id)
        setIsDocumentAvailable(noDocuments)
      }, [uploadList]);


    return (
        <>
            <UploadList
                userId={userId}
                isEdit={type === 'add'}
                list={uploadList?.currentDocs || []}
                saveDocument={dispatch.userDocument.saveDocument}
                deleteDocument={dispatch.userDocument.deleteDocument}
                apiStatus={apiStatus}
                callback={callback}
                downloadDocument={dispatch.viewReport.downloadReport}
                logBook={logBook}
                viewOnly={viewOnly}
            />
        </>
    );
}

export default UploadModule;