import { IconButton } from '@material-ui/core';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import React from 'react';
import Styled from './style';

const FloatingIcon = ({
    open
}) => {
    return (
        <Styled.Wrapper>
            <div className="floating_icon">
                <IconButton onClick={() => open()}>
                    <AssignmentLateIcon fontSize="large" />
                </IconButton>
            </div>
        </Styled.Wrapper>
    );
};

export default FloatingIcon;
