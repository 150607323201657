import { BANNER_VALIDATOR_TIME_IN_S } from '@/utils/constants';
import { getAuthDetails, hasRestrictedRole } from '@/utils/utils';
import moment from 'moment';
import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = lazy(() => import(`@/components/UI/Modal/${layout}`));

const QuestionnaireBanner = lazy(() =>
  import(`@/components/modules/QuestionnaireBanner/Component`)
);

const Questionnaire = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [auth, setAuth] = useState(getAuthDetails());
    const [showBanner, setBanner] = React.useState(false);
    const [bannerContent, setBannerContent] = React.useState(null);
  
  
    const onCancelGigModal = useCallback((isThanks = false) => {
      if (!isThanks) {
        localStorage.setItem('bannerDate', moment());
      }
      setBanner(false);
      setBannerContent(null);
    }, [])
  
    const openGigBanner = useCallback(() => {
      setBannerContent(<QuestionnaireBanner action={() => {
        onCancelGigModal()
      }} />)
      setTimeout(() => { setBanner(true);}, 500);
    }, [])


      useEffect(() => {
        if (auth?.auth && hasRestrictedRole(['FLMANAGER']) && (auth?.showBanner === null || auth?.showBanner === true)) {
          const prevDate = localStorage.getItem('bannerDate');
          if (prevDate === "submitted") {
            return;
          }
          const isOutTimeLimit = prevDate ? moment(moment()).diff(prevDate, 'seconds') > BANNER_VALIDATOR_TIME_IN_S : true;
          if (isOutTimeLimit) {
            dispatch.gigBanner.fetchGiGBanner({
              data: {},
              apiId: 'gig',
              callback: (isSuccess, response, error) => {
                if (isSuccess) {
                  openGigBanner()
                }
              }
            })
          }
        }
      }, [history.location, auth]);

    const memoizedModal = useMemo(() => {
      return (
        <Modal
          open={showBanner}
          className="banner"
          setOpen={onCancelGigModal}
          disableEscapeKeyDown
          disableBackdropClick
          isSuccess
          width={"500px"}
          data={{
            header: '',
            content: () => bannerContent
          }}
          background="black"
        />
      );
    }, [bannerContent, showBanner]);
  return  (<>
  {memoizedModal}
  </>);
};

export default Questionnaire;

Questionnaire.defaultProps = {
};
Questionnaire.propTypes = {
};
